.feedback-container {
    max-width: 1000px;
    margin: auto;
    margin-top: 15px;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  }
  
  .feedback-container h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .feedback-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .feedback-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 6px rgb(0 0 0 / 8%);
  }
  
  .client-name {
    font-size: 18px;
    font-weight: bold;
    color: #ea1d6f;
  }
  
  .client-feedback {
    font-size: 16px;
    color: #555;
    margin: 10px 0;
  }
  
  .client-rating {
    color: #fb0;
    font-size: 18px;
  }
  