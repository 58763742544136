.page-heading {
    margin-top: 0px;
    padding: 10px;
    background-color: white;
}

.help-button {
    z-index: 1000000;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgb(23, 168, 142); /* WhatsApp green */
    color: #fff;
    border: none;
    border-radius: 50px;
    width: 230px;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    cursor: pointer;
    transition: background-color 0.3s;
}

.help-button:hover {
    background-color: #e4a400; /* Hover color */
}

.container {
    background-color: white;
    color: black;
    margin-top: 0;
    padding: 10px;
}

button a {
    color: white;
}

.section-heading h2 {
    color: black;
    max-width: 45%;
}

.section-heading p,
.skill-slide h6 {
    color: black;
    max-width: 40%;
}

.skills {
    width: 85%;
}

.row {
    margin-top: 0 !important;
    display: flex;
    flex-wrap: wrap;
}

.section {
    margin-top: 10px !important;
}
.video-content{
    width: 50%;
    }
    .custom-section-heading h2{
        font-size: 3rem;
    }

/* Responsive Adjustments */
@media (max-width: 768px) {
    .help-button {
        z-index: 1000;
        margin: 10px auto;
    }

    .row {
        flex-direction: column; /* Stack rows */
    }

    .section-heading h2,
    .section-heading p,
    .skill-slide h6 {
        max-width: 100%; /* Expand for smaller screens */
    }

    .skills {
        width: 100%;
    }
}


@media (min-width: 768px) and (max-width: 1199px) {
    #video-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
    }

    #section-video {
        margin-bottom: 30px;
        width: 92vw !important;
    }

    .video-info {
        width: 100%;
    }

    .video-thumb img {
        width: 100%;
    }
    .video-content {
        width: 90%;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .col-lg-6 {
        flex: 0 0 auto;
        width: 100% !important;
    }

    .section-heading h2,
    .section-heading p {
        max-width: 100%; /* Full width for this breakpoint */
    }
    .video-content {
        width: 90%;
    }
}

/* Video Section */
.video-thumb {
    width: 50vw !important;
}

.video-info {
    width: 88vw !important;
}

/* Custom Responsive Container */
.custom-responsive-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.custom-flex-row-responsive {
    display: flex;
    flex-direction: row;
    gap: 20px; /* Add spacing between items */
}

.custom-video-thumb {
    flex: 1 1 50%;
    max-width: 50%;
}

.custom-section-heading,
.custom-skills {
    flex: 1 1 50%;
   
}

@media (max-width: 768px) {
    .custom-flex-row-responsive {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .custom-video-thumb,
    .custom-section-heading,
    .custom-skills {
        flex: 1 1 100%;
        max-width: 100%;
    }
    .video-content {
        width: 90%;
    }
}
