/* General Footer Styles */
.footer {
    background-color: #f8f9fa; /* Light background color */
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    position: relative;
    border: 4px solid #f8f9fa;
    padding: 10px 0 0 0;
    overflow: hidden;
  }
  
  .footer__parralax {
    position: relative;
    height: 10px; /* Adjust height as needed */
    overflow: hidden;
  }
  
  .footer__parralax-trees,
  .footer__parralax-moto,
  .footer__parralax-secondplan,
  .footer__parralax-premierplan,
  .footer__parralax-voiture {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
  }
  
  /* Example positioning for parallax layers */
  .footer__parralax-trees {
    bottom: 0;
    left: 10%;
   
    width: 100px;
    height: 100px;
  }
  
  .footer__parralax-moto {
    bottom: 20px;
 
    width: 150px;
    height: 150px;
  }
  
  /* Add styles for other layers similarly */
  
  /* Footer Content */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer__columns {
    display: flex;
    flex-wrap: wrap;
    width: 100vw !IMPORTANT;
    gap: 20px;
    justify-content: space-between;
  }
  
  .footer__col {
    flex: 1 1 30%; /* Adjust for three columns */
    min-width: 200px;
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer__col-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .footer__nav {
    margin: 0;
    padding: 0;
  }
  
  .footer__nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer__nav-item {
    margin-bottom: 10px;
  }
  
  .footer__nav-link {
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  .footer__nav-link:hover {
    color: #0056b3;
  }
  
  /* Social Media Icons */
  .footer__nav-link i {
    margin-right: 8px;
    color: #333;
  }
  
  /* Footer Copyright */
  .footer__copyrights {
    margin-top: 30px;
    text-align: center;
    font-size: 0.9rem;
    color: #666;
  }
  
  .footer__copyrights a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer__copyrights a:hover {
    color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer__columns {
      flex-direction: column;
    }
  
    .footer__col {
      flex: 1 1 100%;
    }
  }
  