

.seo-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  line-height: 1.6;
  background-color: white;
}

.seo-header {
  text-align: center;
  
}
.seo-example img{
  height: 350px;
}
.seo-header h1 {
  color: black;
  font-size: 4rem;
}

.seo-header h2 {
  color: #555;
  font-size: 1.2rem;
}

.seo-benefits,
.seo-plans,
.seo-example {
  margin-bottom: 30px;
}

.seo-benefits ul,
.seo-plans ul {
  list-style: none;
  padding: 0;
}

.seo-benefits li,
.seo-plans li {
  margin-bottom: 10px;
}

.plan {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
}

.plan h4 {
  color: #39b54a;
}

.seo-chart {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
}
.seo-header {
  text-align: center;
  padding: 20px 0;
}

.seo-main-header {
  font-size: 2.5rem; /* Adjust font size for the header */
  font-weight: 700; /* Bold */
  line-height: 1.2; /* Adjust line height */
  color: #000; /* Black color */
}

.seo-subheader {
  font-size: 1rem; /* Adjust font size for the subheader */
  font-weight: 400; /* Regular */
  color: #555; /* Slightly gray color */
  margin-top: 10px; /* Add some space below the main header */
}

#highlighted-header,.button-submit{
  background-color: #39b54a !important;
}
/* Benefits Section */
.seo-benefits {
 /* Light purple background */
  padding: 30px; /* Add padding for spacing */
  border-radius: 10px; /* Rounded corners */
  text-align: center; /* Center-align the text */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for a 3D effect */
  margin-bottom: 50px;
  transform: translateY(20px); /* Start slightly below */
  animation: fadeInSlideUp 1s ease-out forwards; /* Trigger animation */
}

.benefits-title {
  font-size: 1.8rem; /* Slightly larger font for the title */
  font-weight: 600; /* Bold font */
  color: black; /* Darker text color for better contrast */
  margin-bottom: 20px; /* Space between title and content */
  animation: textZoomIn 1s ease-out forwards; /* Add a zoom-in effect */
}
@keyframes textZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.9); /* Start slightly smaller */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* End at normal size */
  }
}

.pricing-slot {
  transition: transform 0.3s, box-shadow 0.3s;
}
.pricing-slot.highlighted {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

@media (width <= 768px){
 .benefits-list li{
  text-align: start;
 }
}

        
ol {
  counter-reset: li; 
  list-style: none; 
  padding: 0;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
}

ol li {
  position: relative;
  display: block;
  padding: .4em .4em .4em .8em;
  margin: .5em 0 .5em 2.5em;
  background: #39b54a;
  color: white;
  text-decoration: none;
  transition: all .3s ease-out;
}

ol li:hover {background: #DCDDE1;}       
ol li:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  left: -2.5em;
  top: 50%;
  margin-top: -1em;
  background: #000;
  height: 2em;
  width: 2em;
  line-height: 2em;
  text-align: center;
  font-weight: bold;
}


ol li:after {
  position: absolute;
  content: "";
  border: .5em solid transparent;
  left: -1em;
  top: 50%;
  margin-top: -.5em;
  transition: all .3s ease-out;
}

ol li:hover:after {
  left: -.5em;
  border-left-color: #f9dd94;
}
    